@font-face {
  font-family: 'SF UI Display';
  src: local('SF UI Display'), local('SFUIDisplay'), url('./shared/fonts/SFUIDisplay-Regular.ttf') format('truetype');
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: SF UI Display;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ymaps-layers-pane {
  filter: grayscale(1);
  -ms-filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
}
