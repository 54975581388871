@import '../../styles/variables';

.AutoCompleteAddress {
}

.Input {
  width: 100%;
}

.Input {
  width: 100%;
}

.Address {
  :global {
    .MuiAutocomplete-endAdornment {
      display: none;
    }
  }

  input {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;

    mix-blend-mode: normal;

    &::placeholder {
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;

      color: $secondary;

      mix-blend-mode: normal;
      opacity: 0.3;
    }
  }
}
