.Ongoing {
  display: flex;
  height: 100%;

  > div {
    flex: 1;
  }

  > :first-child {
    overflow: scroll;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .Form {
    margin-left: auto;
    margin-right: 0;
    margin-top: auto;
    margin-bottom: auto;
    box-sizing: border-box;
    padding: 40px 48px;
    width: 775px;
  }

  .Close {
    display: none;
  }

  @media all and (max-width: 1549px) {
    .Form {
      width: 100%;
    }
  }

  @media all and (max-width: 1023px) {
    .Form {
      width: 100%;
      padding: 20px 18px;
      box-sizing: border-box;
    }
  }

  @media all and (max-width: 768px) {
    overflow: hidden;
    position: fixed;

    > :last-child {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 100%;
      transition: all 0.6s ease 0s;
      z-index: 1;
    }

    .LeftZero {
      left: 0;
    }

    .Close {
      display: unset;
      position: absolute;
      z-index: 2;
      margin: 20px;
    }
  }
}

.Error {
  font-size: 14px;
  line-height: 17px;

  color: #eb974e;

  position: absolute;
  left: 0;
  bottom: -20px;

  .Link {
    cursor: pointer;
    text-decoration: underline;
  }
}
