@import '../../../../styles/variables';

.Form {
  .Title {
    margin: 0;
    margin-top: 33px;
    font-weight: bold;
    font-size: 30px;
    line-height: 58px;
    color: #000000;

    @media all and (max-width: 1024px) {
      font-size: 32px;
      line-height: 36px;
    }
  }

  .Description {
    margin: 0;
    margin-top: 4px;
    font-size: 16px;
    line-height: 20px;
    color: $secondary;
    mix-blend-mode: normal;
    opacity: 0.99;
    white-space: pre-line;

    @media all and (max-width: 1024px) {
      margin-top: 18px;
    }
  }

  .SubTitle {
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
  }

  .Form {
    margin-top: 36px;

    @media all and (max-width: 768px) {
      margin-top: 30px;
    }

    .Map {
      display: none;

      > button {
        font-weight: bold;
        line-height: 22px;
        margin-top: 30px;
        font-size: 16px;
        font-size: 13px;
        width: 100%;
        justify-content: space-between;
      }

      @media all and (max-width: 768px) {
        display: unset;
      }
    }

    .Block {
      position: relative;
      margin-top: 25px;

      .Subbmit {
        position: relative;
        padding: 8px 70px;

        .CircularProgress {
          position: absolute;
          top: 50%;
          right: 12px;
          margin-top: -12px;
        }

        @media all and (max-width: 768px) {
          width: 100%;
        }
      }

      .Input {
        width: 100%;
      }

      .Address {
        :global {
          .MuiAutocomplete-endAdornment {
            display: none;
          }
        }

        input {
          font-size: 24px;
          line-height: 30px;
          font-weight: bold;

          mix-blend-mode: normal;

          &::placeholder {
            font-size: 24px;
            line-height: 30px;
            font-weight: bold;

            color: $secondary;

            mix-blend-mode: normal;
            opacity: 0.3;
          }
        }
      }

      .Notes {
        input {
          font-size: 16px;
          line-height: 30px;
          font-weight: bold;

          mix-blend-mode: normal;

          &::placeholder {
            font-size: 16px;
            line-height: 30px;
            font-weight: bold;

            color: $secondary;

            mix-blend-mode: normal;
            opacity: 0.3;
          }
        }
      }

      .Error {
        font-size: 14px;
        line-height: 17px;

        color: #eb974e;

        position: absolute;
        left: 0;
        bottom: -20px;
      }
    }
  }

  :global {
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
      padding-right: 0;
    }
  }
}

.Hidden {
  display: none;
}

.Flex {
  display: flex;
  flex-wrap: wrap;
}
