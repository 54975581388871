.Logo {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 312px;

  @media all and (max-width: 768px) {
    width: 285px;
  }

  .FastRiver,
  .PartnerLogo {
    cursor: pointer;

    .Img {
      max-width: 134px;
      height: 40px;
    }
  }
}
