.PromptPanel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 40px;

  @media all and (max-width: 767px) {
    flex-direction: column;
  }

  .Prompt + .Prompt {
    @media all and (max-width: 767px) {
      margin-top: 30px;
    }
  }

  .Prompt {
    flex: 1;
    

    @media all and (max-width: 767px) {
      height: 60px;
      width: 100%;
    }

    .Img {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100px;
      height: 100px;

      background: #eeeeee;

      border-radius: 50px;

      @media all and (max-width: 767px) {
        justify-content: start;
        height: auto;
        width: 40px;
        background: #fff0;

        .Phone {
          width: 18px;
          height: 18px;
        }

        .Pasport {
          width: 19px;
          height: 23px;
        }

        .Document {
          width: 22px;
          height: 24px;
        }

        .Camera {
          width: 22px;
          height: 22px;
        }
      }
    }

    .Block {
      width: 100%;
      @media all and (max-width: 767px) {
        flex: 1;
      }

      .Title {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;

        color: #333333;
        mix-blend-mode: normal;
        margin-top: 30px;

        @media all and (max-width: 767px) {
          margin-top: 0;
        }
      }

      .Description {
        margin-top: 10px;
        font-size: 14px;
        line-height: 18px;

        color: #333333;
        mix-blend-mode: normal;

        @media all and (max-width: 767px) {
          margin-top: 5px;
        }
      }

      .List {
        margin-top: 10px;

        .Title {
          font-weight: bold;
        }

        .Description {
          margin-top: 0;
        }
      }
    }

    @media all and (max-width: 767px) {
      display: flex;
    }
  }

  .PromptWidthMap {
    @media all and (min-width: 767px) {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex: unset;
      width: 50%;

      .Block {
        .Title{ 
          text-align: center;
        }
      }
    }
  }
}
