.TimeIntervals {
  display: flex;
  flex-wrap: wrap;

  margin: -3px -3px;

  > button {
    margin: 3px 3px;
    min-width: 129px;
    flex: 1;
  }

  .Select {
    min-width: 200px;
  }
}

.Block {
  height: 42px;
  align-items: center;
}

.Error {
  font-size: 14px;
  line-height: 16px;

  color: #eb974e;

  .Icon {
    margin-right: 10px;
  }
}
