

.Root {
  .Map {
    height: 100vh;
    width: calc(50vw - 10px);
    position: fixed;
    right: 0;
    z-index: 20;

    @media all and (max-width: 767px) {
      position: fixed;
      left: 100%;
      width: 100vw;
    }
  }

  .OpenButton {
    display: none !important;
    z-index: 10;

    @media all and (max-width: 767px) {
      display: block !important;
    }
  }

  .CloseButton {
    z-index: 30;
  }

  button {
    position: fixed !important;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    padding: 0;
    right: 10px;
    top: 10px;
  }
}

.IsOpenedMap {
  .Map {
    @media all and (max-width: 767px) {
      bottom: 0;
      left: 0;
    }
  }
}