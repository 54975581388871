

.Completed {
  max-width: 1200px;
  margin-top: 30px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  position: relative;

  > div {
    flex: 1;

    @media all and (max-width: 767px) {
      flex: unset;
    }
  }

  .Logo {
    margin-top: 30px;
  }

  .Title {
    margin-top: 68px;
    font-weight: bold;
    font-size: 50px;
    line-height: 58px;

    color: #333333;

     @media all and (max-width: 767px) {
      margin-top: 40px;
      font-size: 36px;
      line-height: 42px;
    }
  }

  .WrapperMapButton {
    height: 50px;
    display: none;
    
    @media all and (max-width: 767px) {
      display: flex;
    }
    
    .MapButton {
      margin-top: 10px;
      position: -webkit-sticky;
      position: sticky;
    }
  }

  .Description {
    margin-top: 39px;
    max-width: 484px;

    @media all and (max-width: 767px) {
      margin-top: 20px;
      font-size: 14px;
      line-height: 18px;

      color: #333333;
      mix-blend-mode: normal;
    }
  }

  .EdditButton {
    margin-top: 20px;

    > button {
      @media all and (max-width: 767px) {
        width: 100%;
      }

      padding: 11px 23px;

      > span {
        font-family: SF UI Display;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;

        color: #ffffff;
      }
    }
  }

  .SubTitle {
    margin-top: 60px;

    font-weight: bold;
    font-size: 24px;
    line-height: 30px;

    color: #333333;
    mix-blend-mode: normal;
    opacity: 0.99;
  }

  .InfoBar {
    margin-top: 40px;
  }

  .Text {
    font-size: 14px;
    line-height: 18px;

    color: #333333;
    mix-blend-mode: normal;
  }

  .BackGround {
    position: absolute;
    left: 20%;
    margin-top: 40px;
    height: 493px;
    width: 100%;
    z-index: -1;
    filter: brightness(120%);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top right;
    background-image: url('https://files.fastriver.ru/ui/moped.png');
    opacity: 0.3;

    @media all and (max-width: 1200px) {
      display: none;
    }
  }
}

.HasMap {
  max-width: unset;


  @media all and (max-width: 767px) {
    justify-content: center;
  }

  .Content {
    margin-left: 0 auto;
    display: flex;
    justify-content: center;


    > div {
      max-width: 600px;
      margin-left: 0 auto;
    }
  }
}

