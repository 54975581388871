@import '../../styles/walk.scss';

@keyframes walkanim {
  to {
    background-position: 100% 0;
  }
}

@keyframes stroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(380%);
  }
}

.LoadingPage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  .Body {
    border: #333333 solid 1px;
    position: relative;

    .ErrorName {
      font-size: 30vh;
      color: #333333;

      text-align: center;
    }

    .Description {
      display: flex;
      padding: 1vh 3vw;
      background-color: #333333;
      color: #fff;
      text-align: center;
      font-size: 4.5vh;

      .Hidden {
        visibility: hidden;
      }
    }

    .WalkContainer {
      position: absolute;

      display: inline-block;
      vertical-align: middle;
      width: 20%;
      top: -65px;
      height: 65px;

      animation: stroll 5s linear infinite;

      > div {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 53px;
        height: 65px;
        background-image: url($walk);
        background-repeat: no-repeat;
        animation: walkanim 1.4s infinite steps(7);
        background-size: 800%;
      }
    }
  }
}
