@import '../../../../../../../../styles/variables';

.CustomDatePicker {
  .CurrentDay {
    > span {
      > p {
        font-family: SF UI Display;
        font-weight: 900;

        color: #333333;
      }
    }
  }

  :global {
    .MuiPickersStaticWrapper-staticWrapperRoot {
      background-color: #fff0;
      min-width: 270px;

      .MuiPickersBasePicker-pickerView {
        justify-content: start;
        margin-top: 30px;
        min-height: auto;
        min-width: 270px;
      }
      .MuiPickersSlideTransition-transitionContainer {
        margin-top: 0;
      }

      .MuiPickersCalendar-week {
        justify-content: start;
      }

      .MuiPickersCalendarHeader-switchHeader,
      .MuiPickersCalendarHeader-daysHeader {
        display: none;
      }
    }
  }
}
