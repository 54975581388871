.InformationBlocks {
  margin-top: 41px;
  display: flex;
  flex-wrap: wrap;

  @media all and (max-width: 767px) {
    margin-top: 26px;
  }

  .Block {
    background: #eeeeee;
    border-radius: 4px;
    padding: 16px 20px;
    box-sizing: border-box;
    max-width: 300px;
    min-width: 300px;
    min-height: 110px;
    display: flex;
    flex-direction: column;

    @media all and (max-width: 767px) {
      margin-left: 0;
      max-width: unset;
      width: 100%;
    }

    &:first-child {
      flex: 1;
    }

    .SubTitle {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px;

      color: #333333;
    }

    .Text {
      font-size: 16px;
      line-height: 20px;
      margin: 0;

      color: #333333;
    }

    .Text + .Text {
      margin-top: 10px;
    }

    .AddToCalendar {
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;

      > button {
        width: 100%;

        > span {
          display: flex;
          justify-content: space-between;

          font-weight: bold;
          font-size: 14px;
          line-height: 18px;

          color: #333333;
          mix-blend-mode: normal;
          opacity: 0.99;
        }
      }

      :global {
        .MuiButton-text {
          padding: 0;
        }
      }
    }
  }

  .Block + .Block {
    margin-left: 18px;

    @media all and (max-width: 767px) {
      margin-top: 14px;
      margin-left: 0;
    }
  }


  .BlockWithMap {
    width: 100%;
    margin-left: 0;
    max-width: unset;
  }
  
  .BlockWithMap + .BlockWithMap {
    margin-top: 14px;
    margin-left: 0;
  }
}
