@import '../../../../../../styles/variables';

$maxWidth: 1383px;

.DateComponent {
  margin-top: 50px !important;

  .Date {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .Title {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;

      color: #333333;
      text-transform: capitalize;
      margin-top: 0;

      cursor: default;
    }

    @media all and (max-width: $maxWidth) {
      display: flex;

      > .Title + .Title {
        margin-left: 20px;
      }

      .Hidden {
        display: none;
      }

      .Pointer {
        cursor: pointer;
      }
      .Shadow {
        color: $secondary;
      }

      .Disabled {
        color: $secondary;
        opacity: 0.3;
      }
    }
  }
}
